.btn-primary {
	background-color: #4e3ae5;
	border: #4e3ae5 2px;
	color: white;
	&:hover {
		background-color: #7161ea;
		border: #4e3ae5 2px;
		color: white;
	}
	&:active {
		background-color: #7161ea;
		border: #7161ea;
		color: white;
	}
	&:focus {
		background-color: #7161ea;
		border: #7161ea;
		color: white;
	}
}
.btn-secondary {
	background-color: #9affd3;
	border: #9affd3;
	color: #0f172a;
	&:hover {
		background-color: #b0ffdd;
		border: #b0ffdd;
		color: #0f172a;
	}
	&:active {
		background-color: #b0ffdd;
		border: #b0ffdd;
		color: #0f172a;
	}
	&:focus {
		background-color: #b0ffdd;
		border: #b0ffdd;
		color: #0f172a;
	}
}

.dropdown-item {
	&:active {
		background-color: #f8fafc;
	}
	&:focus {
		background-color: #f8fafc;
	}
}

#text {
	font-family: Consolas, Lucida Console, monospace;
	font-size: 90%;
}

span.clickable {
	color: #4e3ae5;
	&:hover {
		color: #7161ea;
		text-decoration: none;
	}
}

#create-element {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.95);
	z-index: 1000;
	animation: animationBgOverlay ease-out 0.2s;
	animation-fill-mode: both;
	animation-iteration-count: 1;

	#close {
		position: absolute;
		top: 130px;
		right: 80px;
		animation-duration: 0.1s;
		animation: animationFadeInDown ease-out 0.2s;
		animation-fill-mode: backwards;
		animation-iteration-count: 1;

		#cross {
			cursor: pointer;
			.svg-icon {
				width: 3em;
				height: 3em;
			}

			.svg-icon path,
			.svg-icon polygon,
			.svg-icon rect {
				fill: rgba(155, 153, 175, 1);
			}
		}
	}

	#create-entry {
		position: absolute;
		width: 400px;
		list-style: none;
		margin: 0;
		padding: 0;
		left: 50%;
		top: 40%;
		margin-left: -185px;
		animation-duration: 0.1s;
		animation: animationFadeInDown ease-out 0.2s;
		animation-fill-mode: backwards;
		animation-iteration-count: 1;

		.btn-group {
			position: absolute;
			top: 20%;
			cursor: pointer;
			left: -80px;
		}

		.dropdown-menu {
			&.show {
				overflow-y: scroll;
				height: 300px;
			}
		}

		input {
			padding: 20px;
			width: 100%;
			font-size: 1.8em;
			border: 0;
			border-bottom: 1px solid blue;
			background: #f6f6f6;
			border-radius: 10px;
			&:focus-visible {
				outline: 0;
			}
		}

		span {
			margin-top: 12px;
			display: block;
			text-align: center;
		}

		#add {
			position: absolute;
			top: 20%;
			cursor: pointer;
			right: -15%;
			animation-duration: 0.1s;
			animation: animationFadeInDown ease-out 0.2s;
			animation-fill-mode: backwards;
			animation-iteration-count: 1;

			#done {
				.svg-icon {
					width: 3em;
					height: 3em;
				}

				.svg-icon path,
				.svg-icon polygon,
				.svg-icon rect {
					fill: rgb(30, 177, 54);
				}
			}
		}
	}
}

.draggable {
	.submapLink {
		-webkit-transition: 2s;
		-moz-transition: 2s;
		-o-transition: 2s;
		transition: 2s;
		opacity: 0;
		visibility: hidden;
	}

	&:hover {
		.submapLink {
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			opacity: 1;
			visibility: visible;
		}
	}
}

.submapLink {
	cursor: pointer;
}

#owm-build {
	color: #cbbde2;
	margin-right: 10px;
	font-size: 10px;
}

.draggable {
	cursor: move;
}

.usageContainer {
	flex-direction: column;
	// padding: 20px 0 0 20px;
}

.patron {
	background-color: #f96854;
	color: white;
}

#usageToggle {
	cursor: pointer;
}

#usage {
	margin-top: 20px;
}

.map-view {
	padding-left: 0;
	h5 {
		text-align: center;
	}
}

.app {
	.editor {
		padding: 0;
		background-color: #f8fafc;
	}
}
div.main {
	background-color: white;
	overflow: hidden;
	//border-bottom: 1px solid #6c757d;
}

.breadcrumb {
	padding: 2px 1rem;
	font-size: 0.85rem;
	//border-bottom: 1px solid #6c757d;
	.jumbotron {
		height: 682px;
		padding: 5px;
		margin-bottom: 2px;
	}
}

.tool-bar {
	background: #f8fafc;
	padding: 5px;
	//border-left: 1px solid #6c757d;
	.jumbotron {
		height: 682px;
		padding: 5px;
		margin-bottom: 2px;
	}
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s;

	&.width {
		// flex-grow: 0;
		// min-width: 0;
		transform: translate(100%);
		// width: 0;
		height: auto;
		transition: all 1s;
	}

	// &> .contents{
	// 	width: 500px;
	// 	overflow: hidden;
	// }
}

#controlsMenuControl button {
	margin: 0 2px;
}

.row.usage {
	.col {
		padding-left: 10px;
	}
}

#title {
	margin-bottom: 0;
	margin-top: 5px;
}

#svgMap {
	padding: 10px;
	user-select: none;
}

.subnav {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	background-color: #f8fafc;
	//border-bottom: 1px solid #6c757d;
	//border-radius: 0.25rem;
}

.subnav button {
	margin: 0 2px;
}

.breadcrumb {
	padding: 2px 1rem;
	font-size: 0.85rem;
}

ol.breadcrumb {
	margin-bottom: 0;
}
nav.bc {
	overflow: hidden;
}

nav.navbar {
	color: #4e3ae5;
	background-color: #edebfc;
	padding: 0.4rem 0rem;
	h3 {
		font-size: 1.3rem;
		margin: 0;
	}
	button {
		font-size: 14px;
		line-height: 1.1;
	}
}

#text {
	height: 682px;
}

#htmPane {
	background: #f8fafc;
	padding: 5px;
	padding-left: 12px;
	//border-right: 1px solid #6c757d;
	.jumbotron {
		height: 682px;
		padding: 5px;
		margin-bottom: 2px;
		background-color: #f8fafc;
	}
}

.col {
	&.resizeable {
		resize: horizontal;
		overflow: auto;
	}
}

#meta-container {
	#meta {
		height: 120px;
	}
}

footer {
	background-color: #f8fafc;
	font-size: 86%;
	p {
		color: #0f172a;
	}
	.gh-icon {
		fill: #4e3ae5;
	}
}

body.dark {
	color: #0f172a;
	background-color: #f8fafc;
	a {
		color: #4e3ae5;
		&:hover {
			color: #7161ea;
			text-decoration: none;
		}
	}
	nav.navbar {
		background-color: #f8fafc;
		#owm-build {
			color: #0f172a;
		}
	}
	h5#title {
		color: #0f172a;
	}
	nav.bc {
		ol.breadcrumb {
			background-color: #f8fafc;
			color: #4e3ae5;
			a#url {
				color: #4e3ae5;
			}
		}
	}

	div.main {
		background-color: white;
		overflow: hidden;
		//border-bottom: 1px solid #6c757d;
	}

	div.map-view {
		background-color: white;
	}

	#htmPane {
		//background: #282c34;
	}
}
